// Copyright 2013 Traceur Authors.
//
// Licensed under the Apache License, Version 2.0 (the 'License');
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an 'AS IS' BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {
  ARGUMENTS,
  THIS
} from '../syntax/PredefinedName.js';
import {AlphaRenamer} from './AlphaRenamer.js';
import {FindThisOrArguments} from './FindThisOrArguments.js';

export default function alphaRenameThisAndArguments(tempVarTransformer, tree) {
  let finder = new FindThisOrArguments();
  finder.visitAny(tree);
  if (finder.foundArguments) {
    let argumentsTempName = tempVarTransformer.addTempVarForArguments();
    tree = AlphaRenamer.rename(tree, ARGUMENTS, argumentsTempName);
  }
  if (finder.foundThis) {
    let thisTempName = tempVarTransformer.addTempVarForThis();
    tree = AlphaRenamer.rename(tree, THIS, thisTempName);
  }
  return tree;
}
