// Copyright 2015 Traceur Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import initTailRecursiveFunction from './modules/initTailRecursiveFunction.js';
import call from './modules/call.js';
import continuation from './modules/continuation.js';
import construct from './modules/construct.js';

$traceurRuntime.initTailRecursiveFunction = initTailRecursiveFunction;
$traceurRuntime.call = call;
$traceurRuntime.continuation = continuation;
$traceurRuntime.construct = construct;
