// Copyright 2015 Traceur Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

let {defineProperty, freeze} = Object;
let {slice} = Array.prototype;
let map = Object.create(null);

export default function getTemplateObject(raw, cooked = undefined) {
  var key = raw.join('${}');
  var templateObject = map[key];
  if (templateObject) return templateObject;
  if (!cooked) {
    cooked = slice.call(raw);
  }
  return map[key] = freeze(defineProperty(cooked, 'raw', {value: freeze(raw)}));
}
