// Copyright 2013 Traceur Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// This file was auto generated by build-parse-trees.js
// from trees.json
// Do not edit!

import {ParseTree} from './ParseTree.js';
import * as ParseTreeType from './ParseTreeType.js';

const ANNOTATION = ParseTreeType.ANNOTATION;
export class Annotation extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} name
   * @param {ArgumentList} args
   */
  constructor(location, name, args) {
    super(location);
    this.name = name;
    this.args = args;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformAnnotation(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitAnnotation(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return ANNOTATION;
  }
}

const ANON_BLOCK = ParseTreeType.ANON_BLOCK;
export class AnonBlock extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ParseTree>} statements
   */
  constructor(location, statements) {
    super(location);
    this.statements = statements;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformAnonBlock(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitAnonBlock(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return ANON_BLOCK;
  }
}

const ARGUMENT_LIST = ParseTreeType.ARGUMENT_LIST;
export class ArgumentList extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ParseTree>} args
   */
  constructor(location, args) {
    super(location);
    this.args = args;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformArgumentList(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitArgumentList(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return ARGUMENT_LIST;
  }
}

const ARRAY_COMPREHENSION = ParseTreeType.ARRAY_COMPREHENSION;
export class ArrayComprehension extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ParseTree>} comprehensionList
   * @param {ParseTree} expression
   */
  constructor(location, comprehensionList, expression) {
    super(location);
    this.comprehensionList = comprehensionList;
    this.expression = expression;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformArrayComprehension(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitArrayComprehension(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return ARRAY_COMPREHENSION;
  }
}

const ARRAY_LITERAL = ParseTreeType.ARRAY_LITERAL;
export class ArrayLiteral extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ParseTree>} elements
   */
  constructor(location, elements) {
    super(location);
    this.elements = elements;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformArrayLiteral(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitArrayLiteral(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return ARRAY_LITERAL;
  }
}

const ARRAY_PATTERN = ParseTreeType.ARRAY_PATTERN;
export class ArrayPattern extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ParseTree>} elements
   */
  constructor(location, elements) {
    super(location);
    this.elements = elements;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformArrayPattern(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitArrayPattern(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return ARRAY_PATTERN;
  }
}

const ARRAY_TYPE = ParseTreeType.ARRAY_TYPE;
export class ArrayType extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} elementType
   */
  constructor(location, elementType) {
    super(location);
    this.elementType = elementType;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformArrayType(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitArrayType(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return ARRAY_TYPE;
  }
}

const ARROW_FUNCTION = ParseTreeType.ARROW_FUNCTION;
export class ArrowFunction extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Token} functionKind
   * @param {FormalParameterList} parameterList
   * @param {ParseTree} body
   */
  constructor(location, functionKind, parameterList, body) {
    super(location);
    this.functionKind = functionKind;
    this.parameterList = parameterList;
    this.body = body;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformArrowFunction(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitArrowFunction(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return ARROW_FUNCTION;
  }
}

const ASSIGNMENT_ELEMENT = ParseTreeType.ASSIGNMENT_ELEMENT;
export class AssignmentElement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} assignment
   * @param {ParseTree} initializer
   */
  constructor(location, assignment, initializer) {
    super(location);
    this.assignment = assignment;
    this.initializer = initializer;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformAssignmentElement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitAssignmentElement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return ASSIGNMENT_ELEMENT;
  }
}

const AWAIT_EXPRESSION = ParseTreeType.AWAIT_EXPRESSION;
export class AwaitExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} expression
   */
  constructor(location, expression) {
    super(location);
    this.expression = expression;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformAwaitExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitAwaitExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return AWAIT_EXPRESSION;
  }
}

const BINARY_EXPRESSION = ParseTreeType.BINARY_EXPRESSION;
export class BinaryExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} left
   * @param {Token} operator
   * @param {ParseTree} right
   */
  constructor(location, left, operator, right) {
    super(location);
    this.left = left;
    this.operator = operator;
    this.right = right;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformBinaryExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitBinaryExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return BINARY_EXPRESSION;
  }
}

const BINDING_ELEMENT = ParseTreeType.BINDING_ELEMENT;
export class BindingElement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {BindingIdentifier|ObjectPattern|ArrayPattern} binding
   * @param {ParseTree} initializer
   */
  constructor(location, binding, initializer) {
    super(location);
    this.binding = binding;
    this.initializer = initializer;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformBindingElement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitBindingElement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return BINDING_ELEMENT;
  }
}

const BINDING_IDENTIFIER = ParseTreeType.BINDING_IDENTIFIER;
export class BindingIdentifier extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {IdentifierToken} identifierToken
   */
  constructor(location, identifierToken) {
    super(location);
    this.identifierToken = identifierToken;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformBindingIdentifier(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitBindingIdentifier(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return BINDING_IDENTIFIER;
  }
}

const BLOCK = ParseTreeType.BLOCK;
export class Block extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ParseTree>} statements
   */
  constructor(location, statements) {
    super(location);
    this.statements = statements;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformBlock(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitBlock(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return BLOCK;
  }
}

const BREAK_STATEMENT = ParseTreeType.BREAK_STATEMENT;
export class BreakStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {IdentifierToken} name
   */
  constructor(location, name) {
    super(location);
    this.name = name;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformBreakStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitBreakStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return BREAK_STATEMENT;
  }
}

const CALL_EXPRESSION = ParseTreeType.CALL_EXPRESSION;
export class CallExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} operand
   * @param {ArgumentList} args
   */
  constructor(location, operand, args) {
    super(location);
    this.operand = operand;
    this.args = args;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformCallExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitCallExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return CALL_EXPRESSION;
  }
}

const CALL_SIGNATURE = ParseTreeType.CALL_SIGNATURE;
export class CallSignature extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {TypeParameters} typeParameters
   * @param {FormalParameterList} parameterList
   * @param {ParseTree} returnType
   */
  constructor(location, typeParameters, parameterList, returnType) {
    super(location);
    this.typeParameters = typeParameters;
    this.parameterList = parameterList;
    this.returnType = returnType;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformCallSignature(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitCallSignature(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return CALL_SIGNATURE;
  }
}

const CASE_CLAUSE = ParseTreeType.CASE_CLAUSE;
export class CaseClause extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} expression
   * @param {Array<ParseTree>} statements
   */
  constructor(location, expression, statements) {
    super(location);
    this.expression = expression;
    this.statements = statements;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformCaseClause(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitCaseClause(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return CASE_CLAUSE;
  }
}

const CATCH = ParseTreeType.CATCH;
export class Catch extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} binding
   * @param {ParseTree} catchBody
   */
  constructor(location, binding, catchBody) {
    super(location);
    this.binding = binding;
    this.catchBody = catchBody;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformCatch(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitCatch(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return CATCH;
  }
}

const CLASS_DECLARATION = ParseTreeType.CLASS_DECLARATION;
export class ClassDeclaration extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {BindingIdentifier} name
   * @param {ParseTree} superClass
   * @param {Array<ParseTree>} elements
   * @param {Array<ParseTree>} annotations
   * @param {TypeParameters} typeParameters
   */
  constructor(location, name, superClass, elements, annotations, typeParameters) {
    super(location);
    this.name = name;
    this.superClass = superClass;
    this.elements = elements;
    this.annotations = annotations;
    this.typeParameters = typeParameters;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformClassDeclaration(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitClassDeclaration(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return CLASS_DECLARATION;
  }
}

const CLASS_EXPRESSION = ParseTreeType.CLASS_EXPRESSION;
export class ClassExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {BindingIdentifier} name
   * @param {ParseTree} superClass
   * @param {Array<ParseTree>} elements
   * @param {Array<ParseTree>} annotations
   * @param {TypeParameters} typeParameters
   */
  constructor(location, name, superClass, elements, annotations, typeParameters) {
    super(location);
    this.name = name;
    this.superClass = superClass;
    this.elements = elements;
    this.annotations = annotations;
    this.typeParameters = typeParameters;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformClassExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitClassExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return CLASS_EXPRESSION;
  }
}

const COMMA_EXPRESSION = ParseTreeType.COMMA_EXPRESSION;
export class CommaExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ParseTree>} expressions
   */
  constructor(location, expressions) {
    super(location);
    this.expressions = expressions;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformCommaExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitCommaExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return COMMA_EXPRESSION;
  }
}

const COMPREHENSION_FOR = ParseTreeType.COMPREHENSION_FOR;
export class ComprehensionFor extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} left
   * @param {ParseTree} iterator
   */
  constructor(location, left, iterator) {
    super(location);
    this.left = left;
    this.iterator = iterator;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformComprehensionFor(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitComprehensionFor(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return COMPREHENSION_FOR;
  }
}

const COMPREHENSION_IF = ParseTreeType.COMPREHENSION_IF;
export class ComprehensionIf extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} expression
   */
  constructor(location, expression) {
    super(location);
    this.expression = expression;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformComprehensionIf(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitComprehensionIf(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return COMPREHENSION_IF;
  }
}

const COMPUTED_PROPERTY_NAME = ParseTreeType.COMPUTED_PROPERTY_NAME;
export class ComputedPropertyName extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} expression
   */
  constructor(location, expression) {
    super(location);
    this.expression = expression;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformComputedPropertyName(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitComputedPropertyName(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return COMPUTED_PROPERTY_NAME;
  }
}

const CONDITIONAL_EXPRESSION = ParseTreeType.CONDITIONAL_EXPRESSION;
export class ConditionalExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} condition
   * @param {ParseTree} left
   * @param {ParseTree} right
   */
  constructor(location, condition, left, right) {
    super(location);
    this.condition = condition;
    this.left = left;
    this.right = right;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformConditionalExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitConditionalExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return CONDITIONAL_EXPRESSION;
  }
}

const CONSTRUCT_SIGNATURE = ParseTreeType.CONSTRUCT_SIGNATURE;
export class ConstructSignature extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {TypeParameters} typeParameters
   * @param {FormalParameterList} parameterList
   * @param {ParseTree} returnType
   */
  constructor(location, typeParameters, parameterList, returnType) {
    super(location);
    this.typeParameters = typeParameters;
    this.parameterList = parameterList;
    this.returnType = returnType;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformConstructSignature(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitConstructSignature(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return CONSTRUCT_SIGNATURE;
  }
}

const CONSTRUCTOR_TYPE = ParseTreeType.CONSTRUCTOR_TYPE;
export class ConstructorType extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {TypeParameters} typeParameters
   * @param {FormalParameterList} parameterList
   * @param {ParseTree} returnType
   */
  constructor(location, typeParameters, parameterList, returnType) {
    super(location);
    this.typeParameters = typeParameters;
    this.parameterList = parameterList;
    this.returnType = returnType;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformConstructorType(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitConstructorType(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return CONSTRUCTOR_TYPE;
  }
}

const CONTINUE_STATEMENT = ParseTreeType.CONTINUE_STATEMENT;
export class ContinueStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {IdentifierToken} name
   */
  constructor(location, name) {
    super(location);
    this.name = name;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformContinueStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitContinueStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return CONTINUE_STATEMENT;
  }
}

const COVER_FORMALS = ParseTreeType.COVER_FORMALS;
export class CoverFormals extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ParseTree>} expressions
   */
  constructor(location, expressions) {
    super(location);
    this.expressions = expressions;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformCoverFormals(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitCoverFormals(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return COVER_FORMALS;
  }
}

const COVER_INITIALIZED_NAME = ParseTreeType.COVER_INITIALIZED_NAME;
export class CoverInitializedName extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Token} name
   * @param {Token} equalToken
   * @param {ParseTree} initializer
   */
  constructor(location, name, equalToken, initializer) {
    super(location);
    this.name = name;
    this.equalToken = equalToken;
    this.initializer = initializer;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformCoverInitializedName(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitCoverInitializedName(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return COVER_INITIALIZED_NAME;
  }
}

const DEBUGGER_STATEMENT = ParseTreeType.DEBUGGER_STATEMENT;
export class DebuggerStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   */
  constructor(location) {
    super(location);
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformDebuggerStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitDebuggerStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return DEBUGGER_STATEMENT;
  }
}

const DEFAULT_CLAUSE = ParseTreeType.DEFAULT_CLAUSE;
export class DefaultClause extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ParseTree>} statements
   */
  constructor(location, statements) {
    super(location);
    this.statements = statements;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformDefaultClause(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitDefaultClause(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return DEFAULT_CLAUSE;
  }
}

const DO_WHILE_STATEMENT = ParseTreeType.DO_WHILE_STATEMENT;
export class DoWhileStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Block|ParseTree} body
   * @param {ParseTree} condition
   */
  constructor(location, body, condition) {
    super(location);
    this.body = body;
    this.condition = condition;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformDoWhileStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitDoWhileStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return DO_WHILE_STATEMENT;
  }
}

const EMPTY_STATEMENT = ParseTreeType.EMPTY_STATEMENT;
export class EmptyStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   */
  constructor(location) {
    super(location);
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformEmptyStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitEmptyStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return EMPTY_STATEMENT;
  }
}

const EXPORT_DECLARATION = ParseTreeType.EXPORT_DECLARATION;
export class ExportDeclaration extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} declaration
   * @param {Array<ParseTree>} annotations
   */
  constructor(location, declaration, annotations) {
    super(location);
    this.declaration = declaration;
    this.annotations = annotations;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformExportDeclaration(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitExportDeclaration(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return EXPORT_DECLARATION;
  }
}

const EXPORT_DEFAULT = ParseTreeType.EXPORT_DEFAULT;
export class ExportDefault extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} expression
   */
  constructor(location, expression) {
    super(location);
    this.expression = expression;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformExportDefault(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitExportDefault(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return EXPORT_DEFAULT;
  }
}

const EXPORT_SPECIFIER = ParseTreeType.EXPORT_SPECIFIER;
export class ExportSpecifier extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {IdentifierToken} lhs
   * @param {IdentifierToken} rhs
   */
  constructor(location, lhs, rhs) {
    super(location);
    this.lhs = lhs;
    this.rhs = rhs;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformExportSpecifier(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitExportSpecifier(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return EXPORT_SPECIFIER;
  }
}

const EXPORT_SPECIFIER_SET = ParseTreeType.EXPORT_SPECIFIER_SET;
export class ExportSpecifierSet extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ExportSpecifier>} specifiers
   */
  constructor(location, specifiers) {
    super(location);
    this.specifiers = specifiers;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformExportSpecifierSet(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitExportSpecifierSet(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return EXPORT_SPECIFIER_SET;
  }
}

const EXPORT_STAR = ParseTreeType.EXPORT_STAR;
export class ExportStar extends ParseTree {
  /**
   * @param {SourceRange} location
   */
  constructor(location) {
    super(location);
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformExportStar(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitExportStar(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return EXPORT_STAR;
  }
}

const EXPRESSION_STATEMENT = ParseTreeType.EXPRESSION_STATEMENT;
export class ExpressionStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} expression
   */
  constructor(location, expression) {
    super(location);
    this.expression = expression;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformExpressionStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitExpressionStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return EXPRESSION_STATEMENT;
  }
}

const FINALLY = ParseTreeType.FINALLY;
export class Finally extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Block} block
   */
  constructor(location, block) {
    super(location);
    this.block = block;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformFinally(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitFinally(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return FINALLY;
  }
}

const FOR_IN_STATEMENT = ParseTreeType.FOR_IN_STATEMENT;
export class ForInStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} initializer
   * @param {ParseTree} collection
   * @param {Block|ParseTree} body
   */
  constructor(location, initializer, collection, body) {
    super(location);
    this.initializer = initializer;
    this.collection = collection;
    this.body = body;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformForInStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitForInStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return FOR_IN_STATEMENT;
  }
}

const FOR_OF_STATEMENT = ParseTreeType.FOR_OF_STATEMENT;
export class ForOfStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} initializer
   * @param {ParseTree} collection
   * @param {Block|ParseTree} body
   */
  constructor(location, initializer, collection, body) {
    super(location);
    this.initializer = initializer;
    this.collection = collection;
    this.body = body;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformForOfStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitForOfStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return FOR_OF_STATEMENT;
  }
}

const FOR_ON_STATEMENT = ParseTreeType.FOR_ON_STATEMENT;
export class ForOnStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} initializer
   * @param {ParseTree} observable
   * @param {Block|ParseTree} body
   */
  constructor(location, initializer, observable, body) {
    super(location);
    this.initializer = initializer;
    this.observable = observable;
    this.body = body;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformForOnStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitForOnStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return FOR_ON_STATEMENT;
  }
}

const FOR_STATEMENT = ParseTreeType.FOR_STATEMENT;
export class ForStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} initializer
   * @param {ParseTree} condition
   * @param {ParseTree} increment
   * @param {Block|ParseTree} body
   */
  constructor(location, initializer, condition, increment, body) {
    super(location);
    this.initializer = initializer;
    this.condition = condition;
    this.increment = increment;
    this.body = body;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformForStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitForStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return FOR_STATEMENT;
  }
}

const FORMAL_PARAMETER = ParseTreeType.FORMAL_PARAMETER;
export class FormalParameter extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {BindingElement|RestParameter} parameter
   * @param {ParseTree} typeAnnotation
   * @param {Array<Annotation>} annotations
   */
  constructor(location, parameter, typeAnnotation, annotations) {
    super(location);
    this.parameter = parameter;
    this.typeAnnotation = typeAnnotation;
    this.annotations = annotations;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformFormalParameter(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitFormalParameter(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return FORMAL_PARAMETER;
  }
}

const FORMAL_PARAMETER_LIST = ParseTreeType.FORMAL_PARAMETER_LIST;
export class FormalParameterList extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<FormalParameter>} parameters
   */
  constructor(location, parameters) {
    super(location);
    this.parameters = parameters;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformFormalParameterList(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitFormalParameterList(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return FORMAL_PARAMETER_LIST;
  }
}

const FORWARD_DEFAULT_EXPORT = ParseTreeType.FORWARD_DEFAULT_EXPORT;
export class ForwardDefaultExport extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {IdentifierToken} name
   */
  constructor(location, name) {
    super(location);
    this.name = name;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformForwardDefaultExport(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitForwardDefaultExport(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return FORWARD_DEFAULT_EXPORT;
  }
}

const FUNCTION_BODY = ParseTreeType.FUNCTION_BODY;
export class FunctionBody extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ParseTree>} statements
   */
  constructor(location, statements) {
    super(location);
    this.statements = statements;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformFunctionBody(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitFunctionBody(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return FUNCTION_BODY;
  }
}

const FUNCTION_DECLARATION = ParseTreeType.FUNCTION_DECLARATION;
export class FunctionDeclaration extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {BindingIdentifier} name
   * @param {Token} functionKind
   * @param {FormalParameterList} parameterList
   * @param {ParseTree} typeAnnotation
   * @param {Array<ParseTree>} annotations
   * @param {FunctionBody} body
   */
  constructor(location, name, functionKind, parameterList, typeAnnotation, annotations, body) {
    super(location);
    this.name = name;
    this.functionKind = functionKind;
    this.parameterList = parameterList;
    this.typeAnnotation = typeAnnotation;
    this.annotations = annotations;
    this.body = body;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformFunctionDeclaration(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitFunctionDeclaration(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return FUNCTION_DECLARATION;
  }
}

const FUNCTION_EXPRESSION = ParseTreeType.FUNCTION_EXPRESSION;
export class FunctionExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {BindingIdentifier} name
   * @param {Token} functionKind
   * @param {FormalParameterList} parameterList
   * @param {ParseTree} typeAnnotation
   * @param {Array<ParseTree>} annotations
   * @param {FunctionBody} body
   */
  constructor(location, name, functionKind, parameterList, typeAnnotation, annotations, body) {
    super(location);
    this.name = name;
    this.functionKind = functionKind;
    this.parameterList = parameterList;
    this.typeAnnotation = typeAnnotation;
    this.annotations = annotations;
    this.body = body;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformFunctionExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitFunctionExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return FUNCTION_EXPRESSION;
  }
}

const FUNCTION_TYPE = ParseTreeType.FUNCTION_TYPE;
export class FunctionType extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {TypeParameters} typeParameters
   * @param {FormalParameterList} parameterList
   * @param {ParseTree} returnType
   */
  constructor(location, typeParameters, parameterList, returnType) {
    super(location);
    this.typeParameters = typeParameters;
    this.parameterList = parameterList;
    this.returnType = returnType;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformFunctionType(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitFunctionType(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return FUNCTION_TYPE;
  }
}

const GENERATOR_COMPREHENSION = ParseTreeType.GENERATOR_COMPREHENSION;
export class GeneratorComprehension extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ParseTree>} comprehensionList
   * @param {ParseTree} expression
   */
  constructor(location, comprehensionList, expression) {
    super(location);
    this.comprehensionList = comprehensionList;
    this.expression = expression;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformGeneratorComprehension(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitGeneratorComprehension(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return GENERATOR_COMPREHENSION;
  }
}

const GET_ACCESSOR = ParseTreeType.GET_ACCESSOR;
export class GetAccessor extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {boolean} isStatic
   * @param {ParseTree} name
   * @param {ParseTree} typeAnnotation
   * @param {Array<ParseTree>} annotations
   * @param {FunctionBody} body
   */
  constructor(location, isStatic, name, typeAnnotation, annotations, body) {
    super(location);
    this.isStatic = isStatic;
    this.name = name;
    this.typeAnnotation = typeAnnotation;
    this.annotations = annotations;
    this.body = body;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformGetAccessor(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitGetAccessor(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return GET_ACCESSOR;
  }
}

const IDENTIFIER_EXPRESSION = ParseTreeType.IDENTIFIER_EXPRESSION;
export class IdentifierExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {IdentifierToken} identifierToken
   */
  constructor(location, identifierToken) {
    super(location);
    this.identifierToken = identifierToken;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformIdentifierExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitIdentifierExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return IDENTIFIER_EXPRESSION;
  }
}

const IF_STATEMENT = ParseTreeType.IF_STATEMENT;
export class IfStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} condition
   * @param {Block|ParseTree} ifClause
   * @param {Block|ParseTree} elseClause
   */
  constructor(location, condition, ifClause, elseClause) {
    super(location);
    this.condition = condition;
    this.ifClause = ifClause;
    this.elseClause = elseClause;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformIfStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitIfStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return IF_STATEMENT;
  }
}

const IMPORTED_BINDING = ParseTreeType.IMPORTED_BINDING;
export class ImportedBinding extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} binding
   */
  constructor(location, binding) {
    super(location);
    this.binding = binding;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformImportedBinding(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitImportedBinding(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return IMPORTED_BINDING;
  }
}

const IMPORT_CLAUSE_PAIR = ParseTreeType.IMPORT_CLAUSE_PAIR;
export class ImportClausePair extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} first
   * @param {ParseTree} second
   */
  constructor(location, first, second) {
    super(location);
    this.first = first;
    this.second = second;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformImportClausePair(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitImportClausePair(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return IMPORT_CLAUSE_PAIR;
  }
}

const IMPORT_DECLARATION = ParseTreeType.IMPORT_DECLARATION;
export class ImportDeclaration extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} importClause
   * @param {ParseTree} moduleSpecifier
   */
  constructor(location, importClause, moduleSpecifier) {
    super(location);
    this.importClause = importClause;
    this.moduleSpecifier = moduleSpecifier;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformImportDeclaration(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitImportDeclaration(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return IMPORT_DECLARATION;
  }
}

const IMPORT_SPECIFIER = ParseTreeType.IMPORT_SPECIFIER;
export class ImportSpecifier extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ImportedBinding} binding
   * @param {IdentifierToken} name
   */
  constructor(location, binding, name) {
    super(location);
    this.binding = binding;
    this.name = name;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformImportSpecifier(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitImportSpecifier(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return IMPORT_SPECIFIER;
  }
}

const IMPORT_SPECIFIER_SET = ParseTreeType.IMPORT_SPECIFIER_SET;
export class ImportSpecifierSet extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ImportSpecifier>} specifiers
   */
  constructor(location, specifiers) {
    super(location);
    this.specifiers = specifiers;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformImportSpecifierSet(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitImportSpecifierSet(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return IMPORT_SPECIFIER_SET;
  }
}

const IMPORT_TYPE_CLAUSE = ParseTreeType.IMPORT_TYPE_CLAUSE;
export class ImportTypeClause extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} clause
   */
  constructor(location, clause) {
    super(location);
    this.clause = clause;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformImportTypeClause(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitImportTypeClause(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return IMPORT_TYPE_CLAUSE;
  }
}

const INDEX_SIGNATURE = ParseTreeType.INDEX_SIGNATURE;
export class IndexSignature extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {IdentifierToken} name
   * @param {ParseTree} indexType
   * @param {ParseTree} typeAnnotation
   */
  constructor(location, name, indexType, typeAnnotation) {
    super(location);
    this.name = name;
    this.indexType = indexType;
    this.typeAnnotation = typeAnnotation;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformIndexSignature(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitIndexSignature(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return INDEX_SIGNATURE;
  }
}

const INTERFACE_DECLARATION = ParseTreeType.INTERFACE_DECLARATION;
export class InterfaceDeclaration extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {IdentifierToken} name
   * @param {TypeParameters} typeParameters
   * @param {Array.<ParseTree>} extendsClause
   * @param {ObjectType} objectType
   */
  constructor(location, name, typeParameters, extendsClause, objectType) {
    super(location);
    this.name = name;
    this.typeParameters = typeParameters;
    this.extendsClause = extendsClause;
    this.objectType = objectType;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformInterfaceDeclaration(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitInterfaceDeclaration(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return INTERFACE_DECLARATION;
  }
}

const JSX_ATTRIBUTE = ParseTreeType.JSX_ATTRIBUTE;
export class JsxAttribute extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Token} name
   * @param {ParseTree} value
   */
  constructor(location, name, value) {
    super(location);
    this.name = name;
    this.value = value;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformJsxAttribute(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitJsxAttribute(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return JSX_ATTRIBUTE;
  }
}

const JSX_ELEMENT = ParseTreeType.JSX_ELEMENT;
export class JsxElement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} name
   * @param {Array<ParseTree>} attributes
   * @param {Array<ParseTree>} children
   */
  constructor(location, name, attributes, children) {
    super(location);
    this.name = name;
    this.attributes = attributes;
    this.children = children;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformJsxElement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitJsxElement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return JSX_ELEMENT;
  }
}

const JSX_ELEMENT_NAME = ParseTreeType.JSX_ELEMENT_NAME;
export class JsxElementName extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Token} names
   */
  constructor(location, names) {
    super(location);
    this.names = names;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformJsxElementName(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitJsxElementName(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return JSX_ELEMENT_NAME;
  }
}

const JSX_PLACEHOLDER = ParseTreeType.JSX_PLACEHOLDER;
export class JsxPlaceholder extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} expression
   */
  constructor(location, expression) {
    super(location);
    this.expression = expression;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformJsxPlaceholder(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitJsxPlaceholder(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return JSX_PLACEHOLDER;
  }
}

const JSX_SPREAD_ATTRIBUTE = ParseTreeType.JSX_SPREAD_ATTRIBUTE;
export class JsxSpreadAttribute extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} expression
   */
  constructor(location, expression) {
    super(location);
    this.expression = expression;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformJsxSpreadAttribute(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitJsxSpreadAttribute(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return JSX_SPREAD_ATTRIBUTE;
  }
}

const JSX_TEXT = ParseTreeType.JSX_TEXT;
export class JsxText extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {LiteralToken} value
   */
  constructor(location, value) {
    super(location);
    this.value = value;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformJsxText(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitJsxText(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return JSX_TEXT;
  }
}

const LABELLED_STATEMENT = ParseTreeType.LABELLED_STATEMENT;
export class LabelledStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {IdentifierToken} name
   * @param {ParseTree} statement
   */
  constructor(location, name, statement) {
    super(location);
    this.name = name;
    this.statement = statement;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformLabelledStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitLabelledStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return LABELLED_STATEMENT;
  }
}

const LITERAL_EXPRESSION = ParseTreeType.LITERAL_EXPRESSION;
export class LiteralExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Token} literalToken
   */
  constructor(location, literalToken) {
    super(location);
    this.literalToken = literalToken;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformLiteralExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitLiteralExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return LITERAL_EXPRESSION;
  }
}

const LITERAL_PROPERTY_NAME = ParseTreeType.LITERAL_PROPERTY_NAME;
export class LiteralPropertyName extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Token} literalToken
   */
  constructor(location, literalToken) {
    super(location);
    this.literalToken = literalToken;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformLiteralPropertyName(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitLiteralPropertyName(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return LITERAL_PROPERTY_NAME;
  }
}

const MEMBER_EXPRESSION = ParseTreeType.MEMBER_EXPRESSION;
export class MemberExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} operand
   * @param {IdentifierToken} memberName
   */
  constructor(location, operand, memberName) {
    super(location);
    this.operand = operand;
    this.memberName = memberName;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformMemberExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitMemberExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return MEMBER_EXPRESSION;
  }
}

const MEMBER_LOOKUP_EXPRESSION = ParseTreeType.MEMBER_LOOKUP_EXPRESSION;
export class MemberLookupExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} operand
   * @param {ParseTree} memberExpression
   */
  constructor(location, operand, memberExpression) {
    super(location);
    this.operand = operand;
    this.memberExpression = memberExpression;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformMemberLookupExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitMemberLookupExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return MEMBER_LOOKUP_EXPRESSION;
  }
}

const METHOD = ParseTreeType.METHOD;
export class Method extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {boolean} isStatic
   * @param {Token} functionKind
   * @param {ParseTree} name
   * @param {FormalParameterList} parameterList
   * @param {ParseTree} typeAnnotation
   * @param {Array<ParseTree>} annotations
   * @param {FunctionBody} body
   * @param {ParseTree} debugName
   */
  constructor(location, isStatic, functionKind, name, parameterList, typeAnnotation, annotations, body, debugName) {
    super(location);
    this.isStatic = isStatic;
    this.functionKind = functionKind;
    this.name = name;
    this.parameterList = parameterList;
    this.typeAnnotation = typeAnnotation;
    this.annotations = annotations;
    this.body = body;
    this.debugName = debugName;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformMethod(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitMethod(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return METHOD;
  }
}

const METHOD_SIGNATURE = ParseTreeType.METHOD_SIGNATURE;
export class MethodSignature extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} name
   * @param {boolean} optional
   * @param {CallSignature} callSignature
   */
  constructor(location, name, optional, callSignature) {
    super(location);
    this.name = name;
    this.optional = optional;
    this.callSignature = callSignature;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformMethodSignature(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitMethodSignature(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return METHOD_SIGNATURE;
  }
}

const MODULE = ParseTreeType.MODULE;
export class Module extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ParseTree>} scriptItemList
   * @param {string} moduleName
   */
  constructor(location, scriptItemList, moduleName) {
    super(location);
    this.scriptItemList = scriptItemList;
    this.moduleName = moduleName;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformModule(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitModule(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return MODULE;
  }
}

const MODULE_SPECIFIER = ParseTreeType.MODULE_SPECIFIER;
export class ModuleSpecifier extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Token} token
   */
  constructor(location, token) {
    super(location);
    this.token = token;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformModuleSpecifier(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitModuleSpecifier(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return MODULE_SPECIFIER;
  }
}

const NAME_SPACE_EXPORT = ParseTreeType.NAME_SPACE_EXPORT;
export class NameSpaceExport extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {IdentifierToken} name
   */
  constructor(location, name) {
    super(location);
    this.name = name;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformNameSpaceExport(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitNameSpaceExport(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return NAME_SPACE_EXPORT;
  }
}

const NAME_SPACE_IMPORT = ParseTreeType.NAME_SPACE_IMPORT;
export class NameSpaceImport extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ImportedBinding} binding
   */
  constructor(location, binding) {
    super(location);
    this.binding = binding;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformNameSpaceImport(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitNameSpaceImport(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return NAME_SPACE_IMPORT;
  }
}

const NAMED_EXPORT = ParseTreeType.NAMED_EXPORT;
export class NamedExport extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} exportClause
   * @param {ParseTree} moduleSpecifier
   */
  constructor(location, exportClause, moduleSpecifier) {
    super(location);
    this.exportClause = exportClause;
    this.moduleSpecifier = moduleSpecifier;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformNamedExport(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitNamedExport(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return NAMED_EXPORT;
  }
}

const NEW_EXPRESSION = ParseTreeType.NEW_EXPRESSION;
export class NewExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} operand
   * @param {ArgumentList} args
   */
  constructor(location, operand, args) {
    super(location);
    this.operand = operand;
    this.args = args;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformNewExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitNewExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return NEW_EXPRESSION;
  }
}

const OBJECT_LITERAL = ParseTreeType.OBJECT_LITERAL;
export class ObjectLiteral extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ParseTree>} propertyNameAndValues
   */
  constructor(location, propertyNameAndValues) {
    super(location);
    this.propertyNameAndValues = propertyNameAndValues;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformObjectLiteral(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitObjectLiteral(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return OBJECT_LITERAL;
  }
}

const OBJECT_PATTERN = ParseTreeType.OBJECT_PATTERN;
export class ObjectPattern extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ParseTree>} fields
   */
  constructor(location, fields) {
    super(location);
    this.fields = fields;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformObjectPattern(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitObjectPattern(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return OBJECT_PATTERN;
  }
}

const OBJECT_PATTERN_FIELD = ParseTreeType.OBJECT_PATTERN_FIELD;
export class ObjectPatternField extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} name
   * @param {ParseTree} element
   */
  constructor(location, name, element) {
    super(location);
    this.name = name;
    this.element = element;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformObjectPatternField(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitObjectPatternField(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return OBJECT_PATTERN_FIELD;
  }
}

const OBJECT_TYPE = ParseTreeType.OBJECT_TYPE;
export class ObjectType extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ParseTree>} typeMembers
   */
  constructor(location, typeMembers) {
    super(location);
    this.typeMembers = typeMembers;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformObjectType(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitObjectType(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return OBJECT_TYPE;
  }
}

const PAREN_EXPRESSION = ParseTreeType.PAREN_EXPRESSION;
export class ParenExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} expression
   */
  constructor(location, expression) {
    super(location);
    this.expression = expression;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformParenExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitParenExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return PAREN_EXPRESSION;
  }
}

const POSTFIX_EXPRESSION = ParseTreeType.POSTFIX_EXPRESSION;
export class PostfixExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} operand
   * @param {Token} operator
   */
  constructor(location, operand, operator) {
    super(location);
    this.operand = operand;
    this.operator = operator;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformPostfixExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitPostfixExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return POSTFIX_EXPRESSION;
  }
}

const PREDEFINED_TYPE = ParseTreeType.PREDEFINED_TYPE;
export class PredefinedType extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Token} typeToken
   */
  constructor(location, typeToken) {
    super(location);
    this.typeToken = typeToken;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformPredefinedType(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitPredefinedType(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return PREDEFINED_TYPE;
  }
}

const SCRIPT = ParseTreeType.SCRIPT;
export class Script extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ParseTree>} scriptItemList
   * @param {string} moduleName
   */
  constructor(location, scriptItemList, moduleName) {
    super(location);
    this.scriptItemList = scriptItemList;
    this.moduleName = moduleName;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformScript(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitScript(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return SCRIPT;
  }
}

const PROPERTY_NAME_ASSIGNMENT = ParseTreeType.PROPERTY_NAME_ASSIGNMENT;
export class PropertyNameAssignment extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} name
   * @param {ParseTree} value
   */
  constructor(location, name, value) {
    super(location);
    this.name = name;
    this.value = value;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformPropertyNameAssignment(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitPropertyNameAssignment(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return PROPERTY_NAME_ASSIGNMENT;
  }
}

const PROPERTY_NAME_SHORTHAND = ParseTreeType.PROPERTY_NAME_SHORTHAND;
export class PropertyNameShorthand extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {IdentifierToken} name
   */
  constructor(location, name) {
    super(location);
    this.name = name;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformPropertyNameShorthand(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitPropertyNameShorthand(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return PROPERTY_NAME_SHORTHAND;
  }
}

const PROPERTY_VARIABLE_DECLARATION = ParseTreeType.PROPERTY_VARIABLE_DECLARATION;
export class PropertyVariableDeclaration extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {boolean} isStatic
   * @param {ParseTree} name
   * @param {ParseTree} typeAnnotation
   * @param {Array<ParseTree>} annotations
   * @param {ParseTree} initializer
   */
  constructor(location, isStatic, name, typeAnnotation, annotations, initializer) {
    super(location);
    this.isStatic = isStatic;
    this.name = name;
    this.typeAnnotation = typeAnnotation;
    this.annotations = annotations;
    this.initializer = initializer;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformPropertyVariableDeclaration(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitPropertyVariableDeclaration(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return PROPERTY_VARIABLE_DECLARATION;
  }
}

const PROPERTY_SIGNATURE = ParseTreeType.PROPERTY_SIGNATURE;
export class PropertySignature extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} name
   * @param {boolean} optional
   * @param {ParseTree} typeAnnotation
   */
  constructor(location, name, optional, typeAnnotation) {
    super(location);
    this.name = name;
    this.optional = optional;
    this.typeAnnotation = typeAnnotation;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformPropertySignature(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitPropertySignature(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return PROPERTY_SIGNATURE;
  }
}

const REST_PARAMETER = ParseTreeType.REST_PARAMETER;
export class RestParameter extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {BindingIdentifier} identifier
   */
  constructor(location, identifier) {
    super(location);
    this.identifier = identifier;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformRestParameter(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitRestParameter(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return REST_PARAMETER;
  }
}

const RETURN_STATEMENT = ParseTreeType.RETURN_STATEMENT;
export class ReturnStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} expression
   */
  constructor(location, expression) {
    super(location);
    this.expression = expression;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformReturnStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitReturnStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return RETURN_STATEMENT;
  }
}

const SET_ACCESSOR = ParseTreeType.SET_ACCESSOR;
export class SetAccessor extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {boolean} isStatic
   * @param {ParseTree} name
   * @param {FormalParameterList} parameterList
   * @param {Array<ParseTree>} annotations
   * @param {FunctionBody} body
   */
  constructor(location, isStatic, name, parameterList, annotations, body) {
    super(location);
    this.isStatic = isStatic;
    this.name = name;
    this.parameterList = parameterList;
    this.annotations = annotations;
    this.body = body;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformSetAccessor(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitSetAccessor(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return SET_ACCESSOR;
  }
}

const SPREAD_EXPRESSION = ParseTreeType.SPREAD_EXPRESSION;
export class SpreadExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} expression
   */
  constructor(location, expression) {
    super(location);
    this.expression = expression;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformSpreadExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitSpreadExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return SPREAD_EXPRESSION;
  }
}

const SPREAD_PATTERN_ELEMENT = ParseTreeType.SPREAD_PATTERN_ELEMENT;
export class SpreadPatternElement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} lvalue
   */
  constructor(location, lvalue) {
    super(location);
    this.lvalue = lvalue;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformSpreadPatternElement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitSpreadPatternElement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return SPREAD_PATTERN_ELEMENT;
  }
}

const SUPER_EXPRESSION = ParseTreeType.SUPER_EXPRESSION;
export class SuperExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   */
  constructor(location) {
    super(location);
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformSuperExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitSuperExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return SUPER_EXPRESSION;
  }
}

const SWITCH_STATEMENT = ParseTreeType.SWITCH_STATEMENT;
export class SwitchStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} expression
   * @param {Array<ParseTree>} caseClauses
   */
  constructor(location, expression, caseClauses) {
    super(location);
    this.expression = expression;
    this.caseClauses = caseClauses;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformSwitchStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitSwitchStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return SWITCH_STATEMENT;
  }
}

const SYNTAX_ERROR_TREE = ParseTreeType.SYNTAX_ERROR_TREE;
export class SyntaxErrorTree extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Token} nextToken
   * @param {string} message
   */
  constructor(location, nextToken, message) {
    super(location);
    this.nextToken = nextToken;
    this.message = message;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformSyntaxErrorTree(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitSyntaxErrorTree(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return SYNTAX_ERROR_TREE;
  }
}

const TEMPLATE_LITERAL_EXPRESSION = ParseTreeType.TEMPLATE_LITERAL_EXPRESSION;
export class TemplateLiteralExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} operand
   * @param {Array<ParseTree>} elements
   */
  constructor(location, operand, elements) {
    super(location);
    this.operand = operand;
    this.elements = elements;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformTemplateLiteralExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitTemplateLiteralExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return TEMPLATE_LITERAL_EXPRESSION;
  }
}

const TEMPLATE_LITERAL_PORTION = ParseTreeType.TEMPLATE_LITERAL_PORTION;
export class TemplateLiteralPortion extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Token} value
   */
  constructor(location, value) {
    super(location);
    this.value = value;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformTemplateLiteralPortion(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitTemplateLiteralPortion(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return TEMPLATE_LITERAL_PORTION;
  }
}

const TEMPLATE_SUBSTITUTION = ParseTreeType.TEMPLATE_SUBSTITUTION;
export class TemplateSubstitution extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} expression
   */
  constructor(location, expression) {
    super(location);
    this.expression = expression;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformTemplateSubstitution(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitTemplateSubstitution(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return TEMPLATE_SUBSTITUTION;
  }
}

const THIS_EXPRESSION = ParseTreeType.THIS_EXPRESSION;
export class ThisExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   */
  constructor(location) {
    super(location);
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformThisExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitThisExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return THIS_EXPRESSION;
  }
}

const THROW_STATEMENT = ParseTreeType.THROW_STATEMENT;
export class ThrowStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} value
   */
  constructor(location, value) {
    super(location);
    this.value = value;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformThrowStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitThrowStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return THROW_STATEMENT;
  }
}

const TRY_STATEMENT = ParseTreeType.TRY_STATEMENT;
export class TryStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Block} body
   * @param {Catch} catchBlock
   * @param {Finally} finallyBlock
   */
  constructor(location, body, catchBlock, finallyBlock) {
    super(location);
    this.body = body;
    this.catchBlock = catchBlock;
    this.finallyBlock = finallyBlock;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformTryStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitTryStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return TRY_STATEMENT;
  }
}

const TYPE_ALIAS_DECLARATION = ParseTreeType.TYPE_ALIAS_DECLARATION;
export class TypeAliasDeclaration extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {IdentifierToken} name
   * @param {ParseTree} value
   */
  constructor(location, name, value) {
    super(location);
    this.name = name;
    this.value = value;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformTypeAliasDeclaration(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitTypeAliasDeclaration(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return TYPE_ALIAS_DECLARATION;
  }
}

const TYPE_ARGUMENTS = ParseTreeType.TYPE_ARGUMENTS;
export class TypeArguments extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ParseTree>} args
   */
  constructor(location, args) {
    super(location);
    this.args = args;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformTypeArguments(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitTypeArguments(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return TYPE_ARGUMENTS;
  }
}

const TYPE_NAME = ParseTreeType.TYPE_NAME;
export class TypeName extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {TypeName} moduleName
   * @param {IdentifierToken} name
   */
  constructor(location, moduleName, name) {
    super(location);
    this.moduleName = moduleName;
    this.name = name;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformTypeName(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitTypeName(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return TYPE_NAME;
  }
}

const TYPE_PARAMETER = ParseTreeType.TYPE_PARAMETER;
export class TypeParameter extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {IdentifierToken} identifierToken
   * @param {ParseTree} extendsType
   */
  constructor(location, identifierToken, extendsType) {
    super(location);
    this.identifierToken = identifierToken;
    this.extendsType = extendsType;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformTypeParameter(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitTypeParameter(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return TYPE_PARAMETER;
  }
}

const TYPE_PARAMETERS = ParseTreeType.TYPE_PARAMETERS;
export class TypeParameters extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<TypeParameter>} parameters
   */
  constructor(location, parameters) {
    super(location);
    this.parameters = parameters;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformTypeParameters(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitTypeParameters(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return TYPE_PARAMETERS;
  }
}

const TYPE_REFERENCE = ParseTreeType.TYPE_REFERENCE;
export class TypeReference extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {TypeName} typeName
   * @param {TypeArguments} args
   */
  constructor(location, typeName, args) {
    super(location);
    this.typeName = typeName;
    this.args = args;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformTypeReference(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitTypeReference(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return TYPE_REFERENCE;
  }
}

const UNARY_EXPRESSION = ParseTreeType.UNARY_EXPRESSION;
export class UnaryExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Token} operator
   * @param {ParseTree} operand
   */
  constructor(location, operator, operand) {
    super(location);
    this.operator = operator;
    this.operand = operand;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformUnaryExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitUnaryExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return UNARY_EXPRESSION;
  }
}

const UNION_TYPE = ParseTreeType.UNION_TYPE;
export class UnionType extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {Array<ParseTree>} types
   */
  constructor(location, types) {
    super(location);
    this.types = types;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformUnionType(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitUnionType(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return UNION_TYPE;
  }
}

const VARIABLE_DECLARATION = ParseTreeType.VARIABLE_DECLARATION;
export class VariableDeclaration extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} lvalue
   * @param {ParseTree} typeAnnotation
   * @param {ParseTree} initializer
   */
  constructor(location, lvalue, typeAnnotation, initializer) {
    super(location);
    this.lvalue = lvalue;
    this.typeAnnotation = typeAnnotation;
    this.initializer = initializer;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformVariableDeclaration(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitVariableDeclaration(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return VARIABLE_DECLARATION;
  }
}

const VARIABLE_DECLARATION_LIST = ParseTreeType.VARIABLE_DECLARATION_LIST;
export class VariableDeclarationList extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {TokenType} declarationType
   * @param {Array<VariableDeclaration>} declarations
   */
  constructor(location, declarationType, declarations) {
    super(location);
    this.declarationType = declarationType;
    this.declarations = declarations;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformVariableDeclarationList(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitVariableDeclarationList(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return VARIABLE_DECLARATION_LIST;
  }
}

const VARIABLE_STATEMENT = ParseTreeType.VARIABLE_STATEMENT;
export class VariableStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {VariableDeclarationList} declarations
   */
  constructor(location, declarations) {
    super(location);
    this.declarations = declarations;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformVariableStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitVariableStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return VARIABLE_STATEMENT;
  }
}

const WHILE_STATEMENT = ParseTreeType.WHILE_STATEMENT;
export class WhileStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} condition
   * @param {Block|ParseTree} body
   */
  constructor(location, condition, body) {
    super(location);
    this.condition = condition;
    this.body = body;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformWhileStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitWhileStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return WHILE_STATEMENT;
  }
}

const WITH_STATEMENT = ParseTreeType.WITH_STATEMENT;
export class WithStatement extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} expression
   * @param {Block|ParseTree} body
   */
  constructor(location, expression, body) {
    super(location);
    this.expression = expression;
    this.body = body;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformWithStatement(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitWithStatement(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return WITH_STATEMENT;
  }
}

const YIELD_EXPRESSION = ParseTreeType.YIELD_EXPRESSION;
export class YieldExpression extends ParseTree {
  /**
   * @param {SourceRange} location
   * @param {ParseTree} expression
   * @param {boolean} isYieldFor
   */
  constructor(location, expression, isYieldFor) {
    super(location);
    this.expression = expression;
    this.isYieldFor = isYieldFor;
  }

  /**
   * @param {ParseTreeTransformer} transformer
   */
  transform(transformer) {
    return transformer.transformYieldExpression(this);
  }

  /**
   * @param {ParseTreeVisitor} visitor
   */
  visit(visitor) {
    visitor.visitYieldExpression(this);
  }

  /**
   * @type {ParseTreeType}
   */
  get type() {
    return YIELD_EXPRESSION;
  }
}
