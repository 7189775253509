// Copyright 2016 Traceur Authors.
//
// Licensed under the Apache License, Version 2.0 (the 'License');
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an 'AS IS' BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {ARGUMENTS} from '../syntax/PredefinedName.js';
import {FindInFunctionScope} from './FindInFunctionScope.js';

/**
 * This is used to find whether a function contains a reference to `arguments`.
 */
export default class FindArguments extends FindInFunctionScope {
  visitIdentifierExpression(tree) {
    if (tree.identifierToken.value === ARGUMENTS) {
      this.found = true;
    }
  }
}
